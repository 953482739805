<template>
    <div class="real-friend-privacy">
        <StackRouterHeaderBar />

        <main class="main">
            <h1 class="title m-b-40">
                소개팅 주선을 위한<br />
                개인정보 수집 및 이용 동의
            </h1>

            <div class="m-b-16">
                <p class="m-b-24">
                    주식회사 바닐라브릿지는 회원의 개인정보와, 회원이 제공하는 모든 정보를 안전하게 취급하는데 최선을
                    다합니다.
                </p>

                <p>
                    기입하는 연락처(전화번호 혹은 메시지 어플리케이션 ID)는 회원이 연락처의 실소유주 동의하에 적법하게
                    수집하였으며, 아래의 목적을 위해 주식회사 바닐라브릿지에 제공함을 동의합니다.
                </p>
            </div>

            <div class="hr m-b-16" />

            <ul class="m-b-40">
                <li class="m-b-16">
                    <p class="f-bold m-b-4">목적</p>
                    <p>바닐라브릿지 회원과의 소개팅 주선</p>
                </li>
                <li>
                    <p class="f-bold m-b-4">보유기간</p>
                    <p>연락처 전송 시점으로부터 3개월 보관 후 삭제</p>
                </li>
            </ul>

            <p>
                서비스 이용에 필요한 최소한의 개인정보로 동의 거부시 서비스 이용이 제한됩니다. 더 자세한 내용에 대해서는
                <span class="text-underline f-medium">개인정보처리방침</span>을 참고하시기 바랍니다.
            </p>
        </main>
    </div>
</template>

<script>
export default {
    name: 'RealFriendPrivacyPage',
}
</script>

<style lang="scss" scoped>
.real-friend-privacy {
    .main {
        padding: 0 16px;
        line-height: normal;
        font-size: 15px;
        color: $grey-09;
    }

    .title {
        font-size: 24px;
        color: black;
        font-weight: 500;

        @include f-medium;
    }

    .hr {
        width: 40px;
        height: 4px;
        background-color: $grey-02;
    }
}
</style>
